import state from '@/state'
import i18n from '@/engine/lang'
import type { ChartSeries, IntervalOption } from '@/utilities/chartSessions/chart.types'
import {
  getIntervalDateTime,
  getTotalIntervals,
  getInterval,
  getTotalDatesIntervals
} from '@/utilities/chartSessions/dateIntervals'
import type { PeriodicityEnum } from '@/utilities/chartSessions/chartTypeEnums'
import { chartTypes } from '@/utilities/chartSessions/chartOptions'
import type { ExcludesNullish } from '@wallbox/toolkit-ui'
import { provideSessionUseCases, type Session } from '@/core/session'
import type { Location } from '@/core/location'

const sessionUsesCases = provideSessionUseCases()

function sumArray (array: Session[], key: keyof Session) {
  return array.reduce((a: number, b: Session) => (a += +b[key]), 0)
}

function getIncomeData (sessions: Session[], type: string) {
  return sessions.reduce((a, b) => b.sessionType === type ? a + b.total : a, 0)
}

function fillChartData (series: ChartSeries, sessions: Session[]) {
  const energySum = sumArray(sessions, 'energy') + sumArray(sessions, 'midEnergy')
  const chargingTimeSum = sumArray(sessions, 'chargingTime')
  const totalCostSum = sumArray(sessions, 'totalCost')

  series.energy[0].data.push(sessions.length ? energySum / 1000 : 0)
  series.time[0].data.push(sessions.length ? chargingTimeSum : 0)
  series.sessions[0].data.push(sessions.length)
  series.cost[0].data.push(totalCostSum)

  if (series.income) {
    series.income[0].data.push(getIncomeData(sessions, 'pay_per_charge'))
    series.income[1].data.push(getIncomeData(sessions, 'pay_per_month'))
  }

  return series
}

async function getGroupChargingSessions (startInterval: number, endInterval: number, chargerId?: number) {
  const filters = [
    { field: 'start_time', operator: 'gte', value: Math.floor(startInterval / 1000) },
    { field: 'start_time', operator: 'lt', value: Math.floor(endInterval / 1000) },
    !!chargerId && { field: 'charger_id', operator: 'eq', value: chargerId }
  ].filter(Boolean as unknown as ExcludesNullish)

  const limitPerRequest = 10000
  let sessions: Session[] = []
  let itemsCollected = 0
  let pendingItemsCollected = true

  while (pendingItemsCollected) {
    const response = await Promise.all(
      [0, 1].map(async (index) => {
        return await sessionUsesCases.getAllSessions(
          state.organizations.getCurrentOrganization.groupUid,
          {
            filters,
            limit: limitPerRequest,
            offset: limitPerRequest * index + itemsCollected,
            cache: 'stale'
          }
        )
      })
    )

    sessions = sessions.concat(response.flatMap(res => res.data))
    itemsCollected = sessions.length
    if (response[0].total <= itemsCollected) pendingItemsCollected = false
  }
  return sessions
}

export async function getMonthlyData (currentDate: Date, chargerId?: number) {
  const startInterval = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0).getTime()
  const endInterval = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59).getTime()
  const groupChargingSessions = await getGroupChargingSessions(startInterval, endInterval, chargerId)

  let series: ChartSeries = {
    energy: [{ name: '', data: [] }],
    time: [{ name: '', data: [] }],
    sessions: [{ name: '', data: [] }],
    cost: [{ name: '', data: [] }]
  }

  const labels: Date[] = []
  let sessions = [] as Session[]
  let day = 1
  const month = currentDate.getMonth()
  const year = currentDate.getFullYear()
  const lastDateOfMonth = new Date(year, month + 1, 0).getDate()
  while (day <= lastDateOfMonth) {
    const firstDayOfMonth = new Date(year, month, day, 0, 0, 0).getTime() / 1000
    const lastDayOfMonth = new Date(year, month, day, 23, 59, 59).getTime() / 1000
    sessions = groupChargingSessions.filter(session => {
      return session.startTime > firstDayOfMonth && session.startTime <= lastDayOfMonth
    })

    series = fillChartData(series, sessions)
    labels.push(currentDate)
    day++
  }
  return { labels, series }
}

export async function getData (
  periodicity: PeriodicityEnum,
  interval: IntervalOption,
  dates: Date[],
  chargerId?: number,
  locations?: Location[],
  users?: number[]
) {
  let startTime, endTime, totalIntervals

  if (!dates.length) {
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)
    const { startInterval, endInterval } = getIntervalDateTime(today, interval)
    startTime = startInterval
    endTime = endInterval
    totalIntervals = getTotalIntervals(new Date(), periodicity, interval)
  } else {
    startTime = new Date(dates[0].getFullYear(), dates[0].getMonth(), dates[0].getDate(), 0, 0, 0).getTime()
    endTime = new Date(dates[1].getFullYear(), dates[1].getMonth(), dates[1].getDate(), 23, 59, 59).getTime()
    totalIntervals = getTotalDatesIntervals(dates, periodicity)
  }

  const labels: Date[] = []
  let series: ChartSeries = {
    energy: [{ name: chartTypes.energy.label, data: [] }],
    time: [{ name: chartTypes.time.label, data: [] }],
    sessions: [{ name: chartTypes.sessions.label, data: [] }],
    cost: [{ name: chartTypes.cost.label, data: [] }],
    income: [
      { name: i18n.global.t('mywb.charger.pay-per-charge'), data: [] },
      { name: i18n.global.t('mywb.charger.pay-per-month'), data: [] }
    ]
  }

  const groupChargingSessions = await getGroupChargingSessions(startTime, endTime, chargerId)
  let sessions = [] as Session[]

  let count = 1
  if (periodicity === 'month') {
    count = 0
  }

  while (count <= totalIntervals) {
    const intervalFromDate = (!dates.length) ? new Date() : new Date(endTime)
    const { startTimeInterval, endTimeInterval } = getInterval(intervalFromDate, periodicity, totalIntervals - count)

    sessions = groupChargingSessions.filter(session => {
      const inInterval = session.startTime >= startTimeInterval && session.startTime < endTimeInterval

      let inLocation = true
      if (locations && locations.length > 0) {
        inLocation = locations.some(location => location.id === session.locationId)
      }

      let inUser = true

      if (users && users.length > 0) {
        inUser = users.includes(session.userId)
      }

      return inInterval && inLocation && inUser
    })
    series = fillChartData(series, sessions)
    labels.push(new Date(startTimeInterval * 1000))
    count++
  }

  return { labels, series }
}
